<template>
  <div class="mb-5">
    <InputLabel v-if="label" :id="id" required>
      {{ label }}
    </InputLabel>

    <OnClickOutside class="relative mt-1" @trigger="close">
      <div class="form-element flex items-center" @click="toggle">
        <div v-if="selectedAddress && !opened">
          <div class="text-xs font-normal text-gray-400">
            {{ selectedAddress.formatted.title }}
          </div>
          <div>{{ selectedAddress.formatted.address }}</div>
        </div>
        <div v-else>{{ $t("forms.use_new_address") }}</div>
        <ChevronUpDownIcon class="absolute right-3 text-gray-500 w-5 h-5" />
      </div>

      <transition
        enter-to-class="transform opacity-100"
        enter-class="transform opacity-0 -translate-y-2 scale-y-0"
        leave-to-class="transform opacity-0 -translate-y-2 scale-y-0"
        enter-active-class="transition duration-200 ease-out"
        leave-active-class="transition duration-200 ease-in"
      >
        <div
          v-show="opened"
          ref="options"
          class="options"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          tabindex="-1"
        >
          <div
            class="p-2 py-3 m-1 cursor-pointer hover:bg-gray-100 rounded-sm"
            @click="select({ id: '-1' })"
          >
            {{ $t("forms.use_new_address") }}
          </div>
          <div
            v-for="address in addresses"
            :key="address.id"
            class="p-2 m-1 cursor-pointer hover:bg-gray-100 rounded-sm relative flex items-center space-x-2"
            :class="{ 'bg-gray-50': address.id == address_id }"
            @click="select(address)"
          >
            <div
              v-if="address.id == address_id"
              class="shrink-0 flex items-center right-2 pointer-events-none bg-primary rounded-sm"
            >
              <CheckIcon class="w-4 h-4 text-primary-on" />
            </div>
            <div v-else class="shrink-0 w-4"></div>
            <div>
              <div class="text-xs font-normal text-gray-400 mb-1">
                {{ address.formatted.title }}
              </div>
              <div>{{ address.formatted.address }}</div>
            </div>
          </div>
        </div>
      </transition>
    </OnClickOutside>
  </div>
</template>
<script>
import { OnClickOutside } from "@vueuse/components"
import { CheckIcon, ChevronUpDownIcon } from "@/components/icons"
import InputLabel from "@/components/elements/inputs/_base/InputLabel.vue"

export default {
  components: {
    ChevronUpDownIcon,
    CheckIcon,
    InputLabel,
    OnClickOutside,
  },
  props: {
    modelValue: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    addresses: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue", "input"],
  data() {
    return {
      opened: false,
      address_id: this.modelValue,
      searchQuery: "",
    }
  },
  computed: {
    selectedAddress() {
      if (!this.address_id) return null

      return this.addresses.find(address => address.id == this.address_id)
    },
  },
  methods: {
    select(address) {
      this.address_id = address.id
      this.close()
      this.$emit("update:modelValue", this.address_id)
      this.$emit("input", this.address_id)
    },
    close() {
      this.opened = false
      this.$refs.options && (this.$refs.options.scrollTop = 0)
    },
    open() {
      this.opened = true
    },
    toggle() {
      this.opened = !this.opened
    },
  },
}
</script>

<style scoped>
.form-element {
  @apply h-12 p-3 w-full
         text-gray-600 font-medium text-xs
         bg-white border border-gray-300 rounded shadow-sm
         cursor-pointer select-none
         hover:border-gray-400
         focus-within:shadow-blue-100 focus-within:border-blue-300 focus-within:ring-1 focus-within:ring-blue-300;
}

.options {
  @apply absolute origin-top-left left-0 right-0
         text-gray-600 font-medium text-xs
         p-1 z-50 mt-1
         select-none overflow-y-auto
         bg-white border border-gray-300 rounded shadow;
  max-height: 440px;
}
</style>
